<template>

<div class="container drop-zone"
    @dragover.prevent
    @dragenter.prevent
    @drop="handleDrop">

    <a-scene v-if="fileurl != ''" @loaded="onSceneLoaded" xr-mode-ui="enabled: false">
      <a-entity camera="fov: 120" look-controls ref="camera" ></a-entity>
      <a-sky :src="fileurl" ></a-sky>
    </a-scene>

    <div v-if="urlmy360" class="qrcontainer">
      <qr-code :text="urlmy360" :size="300" ref="qrCode"></qr-code>
    </div>

  </div>

</template>

<script>
import 'aframe';
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  name: 'My360',
  data() {
    return {
      fileurl: '',
      fov: 70,
     // hasQR:false,
      urlmy360:''

    };
  },
  mounted: async function(){
    this.$emit('setHeaderFooter', false);
  },
  methods: {
    handleDrop(event) {
      this.urlmy360 = '';
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        
        reader.onloadend = () => {

          this.fileurl = "";
          this.$nextTick(() => {
            this.fileurl = reader.result;
            this.upload(file);
          });

        };
        
        reader.readAsDataURL(file);
      } else {
        alert("Sleep een geldig afbeeldingsbestand.");
      }
    },
    async upload(file){
      let result = await this.$utils.uploadUser360(file);
      this.urlmy360 = `${window.location.origin}/#/my360/${result.shortCode}`;
    },
    onWheel(event) {
      const zoomSpeed = 2;
      if (event.deltaY > 0) {
        // Zoom uit
        this.fov = Math.min(this.fov + zoomSpeed, 120);
      } else {
        // Zoom in
        this.fov = Math.max(this.fov - zoomSpeed, 30);
      }
      this.$refs.camera.setAttribute('camera', 'fov', this.fov);
    },
    onSceneLoaded() {
      window.addEventListener('wheel', this.onWheel);
    },
  },
  components: {
        'qr-code': VueQRCodeComponent
  },
  computed: {
  },
  beforeDestroy() {
    this.$emit('setHeaderFooter', true);
    window.removeEventListener('wheel', this.onWheel);
  }
  
};
</script>

<style scoped>


.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.drop-zone {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border: 2px dashed #ccc;
  padding: 20px;
}
.drop-zone p {
  font-size: 16px;
  color: #666;
}
button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.qrcontainer{
  position: fixed;
  right:20px;
  top:20px;
}


</style>
