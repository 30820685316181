import Vue from 'vue';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter.js';

export default class Utils {

    store = Vue.prototype.$store;
    bvToast = null;
  
    constructor() {

      this.uploadUserModel = this.uploadUserModel.bind(this);

      this.state = Vue.observable({
        modelDataUrl : "",
        userModelUrl : "",
        uploadStatus : "",
        shortCode : ''
      });
    }

    addsitelog = true;

    gltfExporterOptions = {
        binary: true
    };

    resetState() {
      this.state.modelDataUrl = "";
      this.state.userModelUrl = "";
      this.state.uploadStatus = "";
      this.state.shortCode = '';
    }

    getVerticesCenter(scene) {
      let totalVertices = 0;
      const sumPosition = new THREE.Vector3();  
    
      // Initieer een nieuwe Box3 om de bounding box van alle vertices bij te houden
      const verticesBoundingBox = new THREE.Box3(
        new THREE.Vector3(Infinity, Infinity, Infinity), // min
        new THREE.Vector3(-Infinity, -Infinity, -Infinity) // max
      );
    
      scene.traverse((child) => {
        if (child.isMesh && child.geometry.isBufferGeometry) {
          const positionAttribute = child.geometry.attributes.position;
    
          // Tel het aantal vertices en bereken de som van de X en Z posities (zonder Y)
          for (let i = 0; i < positionAttribute.count; i++) {
            const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
            sumPosition.x += vertex.x;
            sumPosition.z += vertex.z;
            // We negeren de Y-component (de hoogte)
            totalVertices++;
    
            // Update de bounding box met de vertex positie
            verticesBoundingBox.expandByPoint(vertex);
          }
        }
      });
    
      // Bereken het gemiddelde om het centrum in XZ te krijgen, zonder Y
      const center = new THREE.Vector3(
        sumPosition.x / totalVertices,
        0, // Y blijft 0 omdat we de hoogte niet willen beïnvloeden
        sumPosition.z / totalVertices
      );
    
      // Log de bounding box van alle vertices
      console.log('Bounding Box van alle vertices:', verticesBoundingBox);
      console.log('Scene vertices center (XZ):', center);
    
      return center;
    }
    
    centerScene(scene, scaleToPlane, adjustHeight) {
      const meshesToAdjust = [];

      scene.traverse((child) => {
        if (child.isMesh && child.name !== "floor" && child.name !== "Cubes") {
        meshesToAdjust.push(child);
        }
      });

      // Loop door elke mesh en pas de positie aan
      meshesToAdjust.forEach((mesh) => {
        // Sla de wereldpositie van de mesh op
        const matrixWorld = mesh.matrixWorld;
        const positionArray = mesh.geometry.attributes.position.array;
        const worldVertices = [];

        // Verkrijg de wereldvertices
        for (let i = 0; i < positionArray.length; i += 3) {
        const vertex = new THREE.Vector3(positionArray[i], positionArray[i + 1], positionArray[i + 2]);

        // Zet de vertex om naar wereldcoördinaten
        vertex.applyMatrix4(matrixWorld);
        worldVertices.push(vertex);
        }

        // Verplaats de mesh naar de root
        if (mesh.parent) {
        mesh.parent.remove(mesh);
        }

        // Voeg de mesh toe aan de root en zet de positie naar 0,0,0
        scene.add(mesh);
        mesh.position.set(0, 0, 0); // Zet de positie van de mesh naar 0,0,0

        // Reset de rotatie en schaal van de mesh
        mesh.rotation.set(0, 0, 0); // Reset rotatie
        mesh.scale.set(1, 1, 1); // Reset schaal

        // Pas de wereldcoördinaten aan naar lokale coördinaten
        for (let i = 0; i < positionArray.length; i += 3) {
          positionArray[i] = worldVertices[i / 3].x;
          positionArray[i + 1] = worldVertices[i / 3].y;
          positionArray[i + 2] = worldVertices[i / 3].z;
        }

        // Markeer de geometrie als gewijzigd
        mesh.geometry.attributes.position.needsUpdate = true;
      });

      //-----------stap2 center

      // Maak een Box3 om de bounding box van het hele model bij te houden
      const boundingBox = new THREE.Box3();

      // Loop door alle meshes en breid de totale bounding box uit met de bounding box van elke mesh
      meshesToAdjust.forEach((mesh) => {
        mesh.geometry.computeBoundingBox(); // Bereken de bounding box voor deze specifieke geometrie

        // Haal de bounding box van de mesh op en voeg deze toe aan de totale bounding box
        const meshBoundingBox = mesh.geometry.boundingBox;
        boundingBox.union(meshBoundingBox);
      });

      // Bereken het midden van de totale bounding box
      const center = new THREE.Vector3();
      boundingBox.getCenter(center); // Haal het middenpunt op

      const boundingBoxSize = new THREE.Vector3();
      boundingBox.getSize(boundingBoxSize); // Bereken de grootte van de bounding box

      // Loop opnieuw door alle meshes om de vertices aan te passen
      meshesToAdjust.forEach((mesh) => {
        const positionArray = mesh.geometry.attributes.position.array;

        // Vertaal elke vertex zodat het model gecentreerd wordt rond (0, 0, 0)
        for (let i = 0; i < positionArray.length; i += 3) {
          positionArray[i] -= center.x;
          positionArray[i + 2] -= center.z;

          if(adjustHeight){
            const min = boundingBox.min; // De onderkant van de bounding box
            positionArray[i + 1] -= min.y; // Gebruik de onderkant (min.y) voor de y-positie
          }
          else{
            positionArray[i + 1] -= center.y; //center height
          }
        }

        // Markeer de geometrie als bijgewerkt
        mesh.geometry.attributes.position.needsUpdate = true;

        // Bounding Sphere is used for frustum culling; after vertex modifications, 
        // It must be recalculated to prevent Three.js from incorrectly determining that the object is outside the camera's view and not rendering it.
        mesh.geometry.computeBoundingSphere();
      });

      //this.addDebugBoundingBox(scene, boundingBox);

      if(scaleToPlane){
        // Bepaal de schaalfactor gebaseerd op de grootste dimensie van de bounding box (x of z)
        const largestDimension = Math.max(boundingBoxSize.x, boundingBoxSize.z);
        let scaleFactor = 1;

        //upscale if largestDimension is smaller than the upscaleThreshold
        if(largestDimension < this.upscaleThreshold){

          if (largestDimension > 0 && largestDimension < this.planeSize) {
            scaleFactor = this.planeSize / largestDimension;
          }

          meshesToAdjust.forEach((mesh) => {
            mesh.scale.set(scaleFactor, scaleFactor, scaleFactor); // Reset schaal
          });
        }
      }

      scene.updateMatrixWorld(true);
    }

    addDebugBoundingBox(scene, boundingBox){
      // Bereken de grootte van de bounding box
      const boundingBoxSize = new THREE.Vector3();
      boundingBox.getSize(boundingBoxSize);

      // Bereken het midden van de bounding box
      const boundingBoxCenter = new THREE.Vector3();
      boundingBox.getCenter(boundingBoxCenter);

      // Maak een geometrie voor de kubus met dezelfde grootte als de bounding box
      const boxGeometry = new THREE.BoxGeometry(boundingBoxSize.x, boundingBoxSize.y, boundingBoxSize.z);

      // Maak een semi-transparant materiaal
      const boxMaterial = new THREE.MeshStandardMaterial({
      color: 0xff0000,
      transparent: true,
      opacity: 0.3,
    //  wireframe: true // Voeg toe als je ook de randen wilt zien
      });

      // Maak de mesh voor de kubus
      const boundingBoxMesh = new THREE.Mesh(boxGeometry, boxMaterial);

      // Plaats de kubus op het midden van de bounding box
      boundingBoxMesh.position.copy(boundingBoxCenter);

      // Voeg de kubus toe aan de scene
      scene.add(boundingBoxMesh);
    }

    getBoundingBoxMeshes(scene){
      // Maak een lege Box3 om de totale bounding box op te slaan
      const totalBoundingBox = new THREE.Box3();

      // Traverse door de scene om alle meshes te vinden
      scene.traverse((child) => {
        if (child.isMesh) {
          // Bereken de bounding box voor de huidige mesh
          const meshBoundingBox = new THREE.Box3().setFromObject(child);

          // Voeg de bounding box van de mesh toe aan de totale bounding box
          totalBoundingBox.union(meshBoundingBox);
        }
      });

      // Nu bevat 'totalBoundingBox' de volledige bounding box van alle meshes in de scene

      // Optioneel: log de totale bounding box voor debugging
      console.log('Totale Bounding Box:', totalBoundingBox);

      // Je kunt ook het middenpunt en de grootte van de totale bounding box opvragen:
      const center = new THREE.Vector3();
      totalBoundingBox.getCenter(center);
      console.log('Middelpunt van Bounding Box:', center);

      const size = new THREE.Vector3();
      totalBoundingBox.getSize(size);
      console.log('Grootte van Bounding Box:', size);

      return totalBoundingBox;

    }
    
    sceneToSrc(scene){

        const gltfExporter = new GLTFExporter();

        gltfExporter.parse(scene, (glb) => {
            const blob = new Blob([glb], { type: 'model/gltf-binary' });
            const url = URL.createObjectURL(blob);
            this.state.modelDataUrl = url;
        }, undefined, this.gltfExporterOptions);
    }

    async uploadUserModel(scene) {

        console.log("uploadUserModel");

        this.state.uploadStatus = "Preparing upload...";

        const gltfExporter = new GLTFExporter();
        gltfExporter.parse(scene, async (glb) => {
          try {
            const blob = new Blob([glb], { type: 'model/gltf-binary' });

            const formData = new FormData();
            formData.append('file', blob);

            this.state.uploadStatus = "uploading...";

            var url = `${this.store.apiUrl}/saveusermodel`;

            const clientId = this.store.state.clientId;
            if(clientId) url+= `/${clientId}`;

            const response = await fetch(url, {
              method: 'POST',
              body: formData
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            this.state.uploadStatus = "User model uploaded";

            setTimeout(() => {
              this.uploadStatus = "";
            }, 3000); 

            this.state.userModelUrl = `${data.blobUrl}?timestamp=${Date.now()}` ;
            this.state.shortCode = data.shortCode;
           // localStorage.setItem('clientId', data.clientId);

          } catch (error) {
            console.error('Fetch Error:', error);
          }
        }, undefined, this.gltfExporterOptions );
    }

    async uploadMaquette(scene, filename) {  
      console.log("uploadMaquette");  
      const gltfExporter = new GLTFExporter();
      gltfExporter.parse(scene, async (glb) => {
        try {
          const blob = new Blob([glb], { type: 'model/gltf-binary' });
          this.uploadModel(blob, filename,  "Preparing upload...", "Uploading maquette...", "Maquette uploaded." );

        } catch (error) {
          console.error('GLTF Export Error:', error);
        }
      }, undefined, this.gltfExporterOptions);

    }

    async uploadModel(blob, filename, preparetext, uploadingtext, uploadedtext ) {   
      this.state.uploadStatus = preparetext;

      const formData = new FormData();
      formData.append('file', blob);
      formData.append('filename', filename);
      formData.append('clientId', this.store.state.clientId );
      
      this.state.uploadStatus = uploadingtext;

      var url = `${this.store.apiUrl}/savemodel`;

      const response = await fetch(url, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      this.state.uploadStatus = uploadedtext;
      setTimeout(() => {
        this.state.uploadStatus = "";
      }, 3000); 

      this.state.userModelUrl = `${data.blobUrl}?timestamp=${Date.now()}` ;
      this.state.shortCode = data.shortCode;
    };

    async uploadUSDZ(scene, filename) { 
      console.log("uploadusdz");

      this.centerScene(scene, false, true);
      
      const exporter = new USDZExporter();
      exporter.parse(scene, (usdzArrayBuffer) => {

          const blob = new Blob([usdzArrayBuffer], { type: 'model/vnd.usdz+zip' });
        this.uploadModel(blob, filename,  "Preparing upload...",  "Uploading USDZ...",  "Uploaded USDZ" );
        },
        (error) => {
          console.error('Error during conversion:', error);
        }
      ); 
    }

    async uploadUser360(file) {
      try {
        const blob = new Blob([file], { type: file.type });

        const formData = new FormData();
        formData.append('file', blob);

        var url = `${this.store.apiUrl}/saveuser360`;

        const clientId = this.store.state.clientId;
        if(clientId) url+= `/${clientId}`;

        const response = await fetch(url, {
          method: 'POST',
          body: formData
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Fetch Error:', error);
      }
    }

    async getsiteLog(id) {

      try {

        const response = await fetch(`${this.store.apiUrl}/getsitelog`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              clientId: this.store.state.clientId,
              id : id
          })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        if(result.success) return result.data;
        else return null;

        } catch (error) {
            console.error('Error:', error);
            return null; 
        }

    }

    async siteLog(page, id, project) {

        if(this.addsitelog == false) return;
        
        try {

            const response = await fetch(`${this.store.apiUrl}/sitelog`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  clientid: this.store.state.clientId,
                  page: page,
                  project: project,
                  id : id
              })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
            return null; 
        }
    }

    async updateRating(sitelogid, rating) {
      
      try {


        
          const response = await fetch(`${this.store.apiUrl}/updaterating`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                clientid: this.store.state.clientId,
                sitelogid: sitelogid,
                rating: rating
            })
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const data = await response.json();
          return data;
      } catch (error) {
          console.error('Error:', error);
          return null; 
      }
    }

    async AddProjectToShortUrl(shortcode, project) {
      
      try {

          const response = await fetch(`${this.store.apiUrl}/UpdateShorturl`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                clientId: this.store.state.clientId,
                shortCode: shortcode,
                project : project
            })
          });

          return response.ok;
          
      } catch (error) {
          console.error('Error:', error);
          return
      }
    }

    extractFileName(fileUrl) {
      return decodeURIComponent(fileUrl.split('/').pop());
    }

    showToast(message, title, variant) {
      this.bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }

  }