<template>
    <div class="container"> 
        <a-scene v-if="fileurl != ''" @loaded="onSceneLoaded" xr-mode-ui="enabled: false">
          <a-entity camera look-controls ref="camera" ></a-entity>
          <a-sky :src="fileurl"> </a-sky>
        </a-scene>
    </div>

</template>
    
<script>
 
    export default {
      name: 'My360View',
      props: ['shortcode'],
      data() {
        return {
          fileurl: ''
    
        };
      },
      mounted: function(){
        this.getUrl();
      },
      methods: {
        onSceneLoaded() {
        }, 
        async getUrl() {
            await fetch(`${this.$store.apiUrl}/geturl/${this.shortcode}`)
            .then(response => response.json())
            .then(data => {
                //console.log(data);
              this.fileurl = data.url;
            })
            .catch(error => {
              console.error('Fout bij het ophalen van gegevens:', error);
            });
        }
      },
      computed: {
      },
      beforeDestroy() {
      }
      
    };
</script>
    
<style scoped>


.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
}


    
</style>
    