<template>

    <b-container class="content">

      <div v-for="(directory, index) in directoryData.directories" :key="index" >
        <div class="directory">{{ directory.name }}</div>

          <div v-for="(file, fileIndex) in directory.files" :key="fileIndex" class="file button-container" >
            <b-button variant="info" @click="show360(directory.name, file.fileName, file.shortCode)">  
              {{ $utils.extractFileName(file.fileName) }}
              </b-button>
              <b-button variant="info" @click="showQR(file.shortCode)" class="qrbutton">QR</b-button>
          </div> 
        </div>

        <b-modal v-model="showModal" :title="currentUrl">
      <div class="mijn-content">
        <qr-code :text="currentUrl" :size="300" ref="qrCode"></qr-code>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="showModal = false">Sluiten</b-button>
      </template>
    </b-modal>

    </b-container>
    
    </template>
    
    <script>
    
    import VueQRCodeComponent from 'vue-qrcode-component'


    export default {
      name: 'Models',
      data: function () {
        return{
          showModal: false,
          isAndroid: false,
          isIPhone: false,
          isMobile: false,
          directoryData: Object,
          currentUrl: null

        }
      },
      computed:{
        showModelDialog() {   
            return this.currentDirectory != '' && this.currentFile != '' && this.currentShortCode != '';
          } 
      },
      watch: {
      },
      created:function(){    
      },
      mounted:function(){
        this.checkDevice();
        window.addEventListener('resize', this.checkDevice); 
        this.getDataFiles();
      },
     methods: {
      showQR(shorturl){
        this.currentUrl = this.geturl(shorturl);
        this.showModal = true;

      },
        show360(directory, file, short){    
          window.location = `${window.location.origin}/#/360/${short}`;
        },
        geturl(filename){
            return `${window.location.origin}/#/360/${filename}`;
        },
        async getDataFiles() {
          try {
            const response = await fetch(`${this.$store.apiUrl}/GetData`);
            const data = await response.json();

            this.directoryData = data;
            this.$store.baseUrl = data.baseUrl;
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        },
        checkDevice() {
            this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            this.isAndroid = /Android/.test(navigator.userAgent);
            this.isIPhone = /iPhone/.test(navigator.userAgent);
        },
        showModel(directory, file, shortCode){
          this.currentDirectory = directory;
          this.currentFile = file;
          this.currentShortCode = shortCode;

          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });

        }
     },
      components: {
        'qr-code': VueQRCodeComponent
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.checkDevice);
      }
    }
    </script>
    
    <style scoped>

    .directory{
      background-color: #d9d9d9;
      text-align: left;
      font-weight: bold;
    }

    .file{
      background-color: #f4f4f4
    }

    .directory, .file{

      padding: 8px;
      margin-top: 4px;
    }

    .button-container {
      display: flex;
    }

    .qrbutton {
      margin-left: auto;
    }

    .mijn-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    </style>
    
    